import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";

import {
    getNPTH,
    getMiscTag,
    getHTMLBlock,
    getIssue,
    removeComments,
} from "../utils";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import moment from "moment";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const colors = [
    "#d2648e",
    "#ffaa00",
    "#a20039",
    "#0077cc",
    "#2fac93",
    "#ff4d00",
    "#014864",
    "#886893",
    "#f6d500",
];

const TitleCard = ({ title, authors, genre, tag, issue }) => (
    <div
        className="rounded overflow-hidden shadow-lg bg-white sm:min-w-3xl"
        style={{ maxWidth: "80rem", lineHeight: "4rem" }}
    >
        <div className="px-12 pt-12">
            <div
                className="font-serif text-4xl sm:text-5xl flex pb-8"
                style={{
                    position: "relative",
                    fontWeight: 900,
                    textOverflow: "ellipsis",
                    borderBottom: "2px solid rgb(77, 77, 77, .5)",
                    color: "#4D4D4D",
                }}
            >
                {title}
            </div>
            <div className="text-gray-700 text-base text-2xl font-sans text-grey-700 tracking-widest pt-4 pb-8">
                {authors.map((a, i) => (
                    <>
                        <span
                            onClick={() => navigate("/author/" + a.slug)}
                            className="cursor-pointer hover:underline"
                        >
                            {a.name}
                        </span>
                        {i !== authors.length - 1 ? ", " : ""}
                    </>
                ))}{" "}
                {genre && `| ${genre}`} {tag && `| ${tag}`}{" "}
                {issue && (
                    <>
                        |{" "}
                        <span
                            onClick={() => navigate("/" + issue.slug)}
                            className="cursor-pointer hover:underline"
                        >
                            {issue.name.split("-")[0].trim()}
                        </span>
                    </>
                )}
            </div>
        </div>
        <div className="px-6 pt-4">
            {/* <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span> */}
        </div>
    </div>
);

const AuthorSection = ({ authors, created_at, issue }) => (
    <div className="flex flex-row py-12">
        <div className="flex flex-col px-4 justify-between">
            <div className="font-serif text-5xl font-bold">
                By{" "}
                {authors.map((a, i) => {
                    return (
                        <>
                            <span
                                onClick={() => navigate("/author/" + a.slug)}
                                className="cursor-pointer underline"
                            >
                                {a.name}
                            </span>
                            {i < authors.length - 1
                                ? i === authors.length - 2
                                    ? " and "
                                    : ", "
                                : null}
                        </>
                    );
                })}
            </div>
            {authors.map((a) => {
                return <div className="pt-6 font-sans">{a.bio}</div>;
            })}

            <div>
                <div className="font-sans pt-4 tracking-widest">
                    {new Date(created_at).getMonth() > 0 &&
                    new Date(created_at).getMonth() < 4
                        ? `Spring ${moment(created_at).format("YYYY")}`
                        : moment(created_at).format("MMMM YYYY")}
                </div>
                <div className="font-sans tracking-widest">
                    Symposeum Magazine {issue?.split("-")?.[0]?.trim()}
                </div>
            </div>
        </div>
    </div>
);

const Post = ({ data, location }) => {
    const post = data.ghostPost;

    const about = getHTMLBlock(post, "about-poem");

    if (about) {
        data.ghostPost.html = removeComments(data.ghostPost.html);
    }

    const color = colors[Math.floor(Math.random() * colors.length)];
    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <div className="h-24"></div>
            <Layout isIssue>
                <header
                    className="bg-white pt-16 pb-32"
                    style={{
                        backgroundColor: color,
                        position: "absolute",
                        width: "100vw",
                        top: "6rem",
                    }}
                >
                    <div className="flex flex-col items-center justify-center">
                        {/* <h1 className="font-serif text-6xl mb-0">{tag.name}</h1> */}
                        {/* {tag.description ? <p className="font-serif text-6xl mb-8">{tag.description}</p> : null } */}
                    </div>
                </header>
                <div
                    className="container mt-12"
                    style={{
                        position: "relative",
                        justifyContent: "center",
                        marginTop: "4rem",
                    }}
                >
                    <div
                        className="flex flex-row items-center justify-center"
                        style={{
                            zIndex: 5,
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <div>
                            <TitleCard
                                title={post.title}
                                authors={post.authors}
                                issue={getIssue(post)}
                                genre={getNPTH(post) ? getNPTH(post).name : ""}
                                tag={
                                    getMiscTag(post)
                                        ? getMiscTag(post).name
                                        : ""
                                }
                                time={post.reading_time}
                            />
                        </div>
                    </div>
                    <div className="mt-12"></div>
                    <article className="content">
                        {/* { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null } */}
                        <section className="post-full-content">
                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                    <div className="my-8">
                        {/* style={{borderTop: '2px solid rgba(77, 77, 77, 0.5)', borderBottom: '2px solid rgba(77, 77, 77, 0.5)'}} */}
                        <div
                            className="flex flex-row items-center justify-center post-full-content"
                            style={{
                                zIndex: 5,
                                width: "100%",
                                borderTop: "2px solid #e6e6e6",
                                borderBottom: "2px solid #e6e6e6",
                            }}
                        >
                            <AuthorSection
                                authors={post.authors}
                                created_at={post.created_at}
                                issue={post?.primary_tag?.name}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
            reading_time
        }
    }
`;
